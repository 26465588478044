
const Privacy = () => {
  return (
    <>
      <div className="max-w-3xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Datenschutzerklärung</h1>
        <div className="prose">
          <h2>1. Datenerfassung</h2>
          <p>Wir erfassen Informationen, die Sie uns direkt zur Verfügung stellen...</p>
          
          <h2>2. Verwendung der Informationen</h2>
          <p>Wir verwenden die gesammelten Informationen, um...</p>
          
          <h2>3. Datenschutz</h2>
          <p>Wir setzen geeignete technische und organisatorische Maßnahmen um...</p>
          
          <h2>4. Ihre Rechte</h2>
          <p>Sie haben das Recht auf Auskunft, Berichtigung oder Löschung Ihrer personenbezogenen Daten...</p>
          
          <h2>5. Kontakt</h2>
          <p>Für datenschutzbezogene Anfragen kontaktieren Sie uns bitte unter datenschutz@zondiac.com</p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
